<template>
  <div
    class="sm:border animation-default flex rounded-sm border-neutral-50 bg-white animation-fadeIn animation-once hover:border-neutral-100"
  >
    <router-link
      :to="{
        name: 'dish-detail',
        params: { dishUuid: dish.uuid },
      }"
      class="flex-grow flex min-w-0 p-4 md:items-center"
    >
      <div
        class="relative mr-4 flex h-8 w-10 flex-shrink-0 items-center justify-center overflow-hidden rounded-sm bg-neutral-50 md:h-18 md:w-24"
      >
        <ResizedImage
          v-if="dish.coverImage"
          :src="dish.coverImage.url"
          :width="128"
          :height="72"
          crop
          class="absolute top-0 left-0 h-full w-full object-cover"
        />
        <IconBorettis v-else class="h-16 w-16 text-neutral-300" />
      </div>
      <div class="overflow-hidden">
        <h3 class="truncate text-sm font-medium sm:text-base">
          {{ dish.name }}
        </h3>
        <p class="truncate mb-1 text-xs text-neutral-600">
          {{ dish.description }}
        </p>
        <div class="truncate flex text-xs text-neutral-400">
          <span class="mr-1">{{ dish.course.name }}</span>
          <span v-if="dish.prepTime" class="mr-1">/</span>
          <span v-if="dish.prepTime" class="mr-1">
            {{ dish.prepTime }}
            {{ $t("components.menus.min") }}
          </span>
          <span v-if="dish.salesPrice" class="mr-1">/</span>
          <span v-if="dish.salesPrice">
            {{ formatPrice(dish.salesPrice) }}
          </span>
        </div>
      </div>
    </router-link>
    <div class="flex flex-col justify-between">
      <DishToggle
        v-if="($can('write:menu') && organisations.length > 0) || isEditing"
        :dish="dish"
      />
      <div class="flex-grow hidden w-12 items-center justify-center sm:flex">
        <OverflowMenu :items="menuItems" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OverflowMenu from "@/components/common/OverflowMenu";
import DishToggle from "@/components/menus/DishToggle";

export default {
  components: {
    OverflowMenu,
    DishToggle,
  },

  props: {
    dish: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      menuItems: [
        {
          name: this.$t("global.viewDetails"),
          showIf: true,
          callback: () =>
            this.$router.push({
              name: "dish-detail",
              params: { dishUuid: this.dish.uuid },
            }),
        },
        {
          name: this.$t("components.menus.editDish"),
          showIf: this.$can("write:menu"),
          callback: () =>
            this.$router.push({
              name: "edit-dish",
              params: { dishUuid: this.dish.uuid },
            }),
        },
      ],
    };
  },

  computed: {
    ...mapGetters("coreMenu", ["organisations", "isEditing"]),
  },

  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      }).format(price);
    },
  },
};
</script>
