<template>
  <div>
    <div class="container">
      <PageIntro>
        <template v-slot:title>
          {{ $t("components.menus.myMenu") }}
        </template>
        <template v-slot:description>
          {{ $t("components.menus.myMenuDescription") }}
        </template>
      </PageIntro>
    </div>
    <div v-if="myMenu && myMenuWithoutEmptyCourses.length" class="sm:container">
      <div class="grid gap-8">
        <div v-for="course in myMenuWithoutEmptyCourses" :key="course.uuid">
          <h2 class="mb-4 px-5 text-xs font-medium uppercase sm:px-0">
            {{ course.name }}
          </h2>
          <div
            class="col-gap-8 row-gap-1 lg:row-gap-2 grid grid-cols-1 xl:grid-cols-2"
          >
            <DishRow
              v-for="dish in course.dishes"
              :key="dish.uuid"
              :dish="dish"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="myMenuError" class="container">
      <ErrorAlert :text="myMenuError" />
    </div>
    <div v-else class="pt-5 text-center text-sm text-neutral-600 container">
      {{ $t("components.menus.noMenuFound") }}
    </div>
  </div>
</template>

<script>
import PageIntro from "@/components/menus/PageIntro";
import DishRow from "@/components/menus/DishRow";
import ErrorAlert from "@/components/common/ErrorAlert";

import store from "@/store";
import { mapGetters } from "vuex";

export default {
  name: "Menus",

  components: {
    PageIntro,
    DishRow,
    ErrorAlert,
  },

  beforeRouteEnter(to, _from, next) {
    Promise.all([
      store.dispatch("dish/fetchDishCourses"),
      store.dispatch("myMenu/findMyMenu"),
    ]).finally(() => {
      next();
    });
  },

  computed: {
    ...mapGetters("myMenu", ["myMenu", "myMenuError"]),

    myMenuWithoutEmptyCourses() {
      return this.myMenu.courses.filter((course) => course.dishes.length);
    },
  },
};
</script>
