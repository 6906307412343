<template>
  <div class="relative">
    <input
      :id="'dish-' + dish.uuid"
      type="checkbox"
      class="absolute top-0 opacity-0"
      :checked="isSelected"
      @change="select()"
    />
    <label
      :for="'dish-' + dish.uuid"
      class="border-l border-b relative z-10 flex h-12 w-12 cursor-pointer items-center justify-center rounded-bl-sm border-neutral-50 bg-neutral-0 transition-all duration-200"
    >
      <IconPlus />
      <IconTick class="text-primary-600" />
    </label>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    dish: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("coreMenu", ["dishes"]),
    isSelected() {
      return !!this.dishes.find((d) => d.uuid === this.dish.uuid);
    },
  },
  methods: {
    ...mapActions("coreMenu", ["addDish", "removeDish"]),
    select() {
      if (this.isSelected) {
        this.removeDish(this.dish);
      } else {
        this.addDish(this.dish);
      }
    },
  },
};
</script>

<style scoped>
label svg:last-child,
input:checked + label svg:first-child {
  @apply hidden;
}
input:checked + label {
  @apply border-primary-50 bg-primary-50;
}
input:checked + label svg:last-child {
  @apply flex;
}
</style>
